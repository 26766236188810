<template>
  <div>
    <b-row>
      <b-col>
        <b-form-group label="Đơn vị đối soát(*)">
          <b-form-select
            v-model="inputWallet.wallet_type"
            :options="option.wallet_type"
            required
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Loại giao dịch(*)">
          <b-form-select
              v-model="inputWallet.type"
              :options="option.type"
              required
          ></b-form-select>

        </b-form-group>
      </b-col>
      <b-col v-if="inputWallet.type != 'SHIP_COD'">
        <b-form-group label="Mã giao dịch ví">
          <b-form-input
              v-model="inputWallet.wallet_transaction_id"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col v-if="inputWallet.type != 'SHIP_COD'">
        <b-form-group label="Mã đối tác">
          <b-form-input
              v-model="inputWallet.partner_code"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col v-if="inputWallet.type == 'SHIP_COD'">
        <b-form-group label="Số điện thoại">
          <b-form-input
              v-model="inputWallet.user_phone"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col v-if="inputWallet.type == 'SHIP_COD'">
        <b-form-group label="Mã thanh toán">
          <b-form-input
              v-model="inputWallet.payment_id"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Ngày bắt đầu (*)">
          <b-form-input
            :id="`type-date`"
            :type="`date`"
            v-model="inputWallet.from"
            required
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Ngày kết thúc (*)">
          <b-form-input
            :id="`type-date`"
            :type="`date`"
            v-model="inputWallet.to"
            required
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="inputWallet.type != 'SHIP_COD'">
      <b-col >
        <b-form-group label="Tên đối tác">
          <b-form-select
              v-model="inputWallet.partner_name"
              :options="option.partner_name"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col></b-col>
      <b-col></b-col>
      <b-col></b-col>
      <b-col></b-col>
      <b-col></b-col>
    </b-row>
    <b-row v-if="inputWallet.type == 'SHIP_COD'">
      <b-col >
        <b-form-group label="Mã order đối tác">
          <b-form-input
              v-model="inputWallet.merchant_order_no"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col >
        <b-form-group label="Mã hóa đơn đối tác">
          <b-form-input
              v-model="inputWallet.merchant_bill_code"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col >
        <b-form-group label="Mã hợp đồng">
          <b-form-input
              v-model="inputWallet.bill_code"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col >
        <b-form-group label="Đối tác">
          <b-form-select
              v-model="inputWallet.action"
              :options="option.action"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col >
        <b-form-group label="Trạng thái">
          <b-form-select
              v-model="inputWallet.status"
             :options="option.status"
          ></b-form-select>
        </b-form-group>
      </b-col>


      <b-col></b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from "vue";
import Multiselect from "vue-multiselect";
import Common from "@/core/mixins/common";
Vue.component("multiselect", Multiselect);

export default {
  props: ["walletType", "optionWalletType"],
  mixins: [Common],
  data() {
    return {
      inputWallet: {
        bill_code:null,
        partner_name:null,
        from: this.getYesterday(),
        to: this.getCurrentDay(),
        code: null,
        type: 'BEHALF_WALLET',
        user_phone: null,
        status: null,
        trans_status: null,
        payment_id: null,
        account_name: null,
        partner_transaction_id: null,
        partner_request_id: null,
        wallet_type: this.walletType,
        wallet_transaction_id: null,
        partner_code:null,
        merchant_order_no:null,
        merchant_bill_code:null,
        action: null
      },
      showBEHALF_WALLET: true,
      showREPLACEMENT: true,
      option: {
         partner_name: [
          { value: null, text: "Tất cả" },
          { value: "Payermax", text: "Payermax" },
          { value: "FastMoney_EVNFC", text: "FastMoney_EVNFC" },
          { value: "ABAcare", text: "ABAcare" },
          { value: "9Service", text: "9Service" },
        ],
        wallet_type: this.optionWalletType,
        type: [
          { value: "SHIP_COD", text: "Thu hộ Ví" },
          { value: "BEHALF_WALLET", text: "Chi hộ Ví" },
        ],
        status: [
          { value: null, text: "ALL" },
          { value: "FAIL", text: "FAIL" },
          { value: "PENDING", text: "PENDING" },
          { value: "SUCCESS", text: "SUCCESS" },
          { value: "REFUND", text: "REFUND" },
          { value: "CREATED", text: "CREATED" },
          { value: "PROCESSING", text: "PROCESSING" },
          { value: "CANCEL", text: "CANCEL" },
          { value: "REJECT", text: "REJECT" },
          { value: "EXPIRED_SESSION", text: "EXPIRED_SESSION" },
          { value: "PAYMENT_PENDING", text: "PAYMENT_PENDING" },
          { value: "PAYMENT_CANCEL", text: "PAYMENT_CANCEL" },
          { value: "PAYMENT_FAIL", text: "PAYMENT_FAIL" },
          { value: "PAYMENT_SUCCESS", text: "PAYMENT_SUCCESS" },
          { value: "WAIT_TO_REVIEW", text: "WAIT_TO_REVIEW" },
        ],
        action : [
          { value: null, text: "ALL" },
          { value: "FASTMONEY", text: "FASTMONEY" },
          { value: "TIKOP", text: "TIKOP" },
          { value: "Payermax", text: "Payermax" },
          { value: "ABAcare", text: "ABAcare" },
          { value: "9Service", text: "9Service" },
        ],

      },
    };
  },
  methods: {},
  watch: {
    "inputWallet.wallet_type"() {
      this.$emit("updateWalletType", this.inputWallet.wallet_type);
    },
    "inputWallet.type"() {
      if (this.inputWallet.type === "BEHALF_WALLET") {
        this.showBEHALF_WALLET = true;
        this.showREPLACEMENT = false;
      } else {
        this.showBEHALF_WALLET = false;
        this.showREPLACEMENT = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
button.btn.btn-danger {
  margin-left: 5px;
}
</style>
