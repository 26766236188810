<template>
  <div>
    <b-row>
      <b-col>
        <b-form-group label="Đơn vị đối soát(*)">
          <b-form-select
            v-model="input9S.wallet_type"
            :options="option.wallet_type"
            required
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Loại giao dịch(*)">
          <b-form-select
            v-model="input9S.type"
            :options="option.type"
            required
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Ngày bắt đầu (*)">
          <b-form-input
            :id="`type-date`"
            :type="`date`"
            :max="input9S.type == 'DEPOSIT' ? '2023-09-15' : ''"
            :min="input9S.type == 'DEPOSIT_V2' ? '2023-09-15' : ''"
            v-model="input9S.from"
            required
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Ngày kết thúc (*)">
          <b-form-input
            :id="`type-date`"
            :type="`date`"
            :max="input9S.type == 'DEPOSIT' ? '2023-09-15' : ''"
            :min="input9S.type == 'DEPOSIT_V2' ? '2023-09-15' : ''"
            v-model="input9S.to"
            required
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <div class="" v-if="input9S.type == 'DEPOSIT'">
      <b-row>
        <b-col>
          <b-form-group label="Mã giao dịch">
            <b-form-input v-model="input9S.code"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="SĐT ví">
            <b-form-input v-model="input9S.phone"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Trạng thái ví">
            <b-form-select
              v-model="input9S.status"
              :options="option.status"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col></b-col>
      </b-row>
    </div>
    <div class="" v-if="input9S.type == 'DEPOSIT_V2'">
      <b-row>
        <b-col>
          <b-form-group label="Mã thanh toán">
            <b-form-input v-model="input9S.transaction_code"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Mã giao dịch">
            <b-form-input v-model="input9S.code"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Mã transfer">
            <b-form-input v-model="input9S.transfer_code"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Loại">
            <b-form-select
                v-model="input9S.action"
            :options="option.action">

            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Trạng thái transfer">
            <b-form-select
              v-model="input9S.status"
              :options="option.trans_status"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <div class="" v-if="input9S.type == 'REPLACEMENT'">
      <b-row>
        <b-col>
          <b-form-group label="Mã đối tác">
            <b-form-input v-model="input9S.partner_transaction_id"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Mã giao dịch">
            <b-form-input v-model="input9S.code"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Mã yêu cầu đối tác">
            <b-form-input v-model="input9S.partner_request_id"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="SĐT">
            <b-form-input v-model="input9S.phone"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Số tài khoản">
            <b-form-input v-model="input9S.account_no"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Tên tài khoản">
            <b-form-input v-model="input9S.account_name"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Trạng thái giao dịch">
            <b-form-select
                v-model="input9S.trans_status"
                :options="option.trans_status"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Multiselect from "vue-multiselect";
import Common from "@/core/mixins/common";
Vue.component("multiselect", Multiselect);

export default {
  props: ["walletType", "optionWalleType"],
  mixins: [Common],
  data() {
    return {
      input9S: {
        from: this.getYesterday(),
        to: this.getCurrentDay(),
        code: null,
        type: 'DEPOSIT',
        phone: null,
        status: null,
        trans_status: null,
        account_no: null,
        account_name: null,
        partner_transaction_id: null,
        partner_request_id: null,
        wallet_type: this.walletType,
        transaction_code: null,
        action: null,
        transfer_code: null,
      },
      showDEPOSIT: true,
      showREPLACEMENT: true,
      option: {
        wallet_type: this.optionWalleType,
        action: [
          { value: null, text: "ALL" },
          { value: "DEPOSIT", text: "DEPOSIT" },
          { value: "PAYMENT", text: "PAYMENT" },
        ],
        status: [
          { value: null, text: "ALL" },
          { value: "-2", text: "CANCELED" },
          { value: "-1", text: "FAIL" },
          { value: "0", text: "PENDING" },
          { value: "1", text: "SUCCESS" },
          { value: "2", text: "REVERSED" },
        ],
        trans_status: [
          { value: null, text: "ALL" },
          { value: "SUCCESS", text: "SUCCESS" },
          { value: "CANCEL", text: "CANCEL" },
          { value: "CREATED", text: "CREATED" },
          { value: "FAIL", text: "FAIL" },
          { value: "PENDING", text: "PENDING" },
        ],
        type: [
          { value: "DEPOSIT", text: "Nạp tiền chuyển khoản (cũ)" },
          { value: "DEPOSIT_V2", text: "Thu hộ 9S (mới)" },
          { value: "REPLACEMENT", text: "Chi hộ 9S" },
        ],
      },
    };
  },
  methods: {},
  watch: {
    "input9S.wallet_type"() {
      this.$emit("updateWalletType", this.input9S.wallet_type);
    },
    "input9S.type"() {
      // if (this.input9S.type === "DEPOSIT") {
      //   this.showDEPOSIT = true;
      //   this.showREPLACEMENT = false;
      // } else {
      //   this.showDEPOSIT = false;
      //   this.showREPLACEMENT = true;
      // }
    },
  },
};
</script>
<style lang="scss" scoped>
button.btn.btn-danger {
  margin-left: 5px;
}
</style>
