<template>
  <div>
    <b-row>
      <b-col>
        <b-form-group label="Đơn vị đối soát(*)">
          <b-form-select
            v-model="inputFormPaygateCard.wallet_type"
            :options="option.wallet_type"
            required
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Tìm kiếm theo">
          <b-form-select
            v-model="inputFormPaygateCard.type_time"
            :options="option.type_time"
            required
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Ngày bắt đầu (*)">
          <b-form-input
            :id="`type-date`"
            :type="`date`"
            v-model="inputFormPaygateCard.from"
            required
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Ngày kết thúc (*)">
          <b-form-input
            :id="`type-date`"
            :type="`date`"
            v-model="inputFormPaygateCard.to"
            required
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

      <b-row>
        <b-col>
          <b-form-group label="Mã thanh toán">
            <b-form-input v-model="inputFormPaygateCard.code"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Mã giao dịch">
            <b-form-input v-model="inputFormPaygateCard.wallet_transaction_id"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="SĐT Ví">
            <b-form-input v-model="inputFormPaygateCard.user_phone"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Trạng thái giao dịch">
            <b-form-select
              v-model="inputFormPaygateCard.status"
              :options="option.status"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
  </div>
</template>

<script>
import Vue from "vue";
import Multiselect from "vue-multiselect";
import Common from "@/core/mixins/common";
Vue.component("multiselect", Multiselect);

export default {
  props: ["walletType", "optionWalleType"],
  mixins: [Common],
  data() {
    return {
      inputFormPaygateCard: {
        from: null,
        to: null,
        code: null,
        type: null,
        user_phone: null,
        type_time: 'created_at',
        status: null,
        wallet_transaction_id: null,
        wallet_type: this.walletType,
      },
      showDEPOSIT: true,
      showREPLACEMENT: true,
      option: {
        wallet_type: this.optionWalleType,
        type_time: [
          { value: "created_at", text: "Thời gian tạo" },
          { value: "completed_at", text: "Thời gian hoàn thành" },
        ],
        status: [
          {value: null, text: "ALL"},
          {value: "FAIL", text: "FAIL"},
          {value: "FALSE", text: "FALSE"},
          {value: "PENDING", text: "PENDING"},
          {value: "PROCESSING", text: "PROCESSING"},
          {value: "OFFSET", text: "OFFSET"},
          {value: "SUCCESS", text: "SUCCESS"},
          {value: "REFUND", text: "REFUND"},
          {value: "REVERSED", text: "REVERSED"},
          {value: "CANCEL", text: "CANCEL"},
          {value: "CREATE", text: "CREATE"},
          {value: "CREATED", text: "CREATED"},
          {value: "CREATE_REPLACE", text: "CREATE_REPLACE"},
          {value: "CREATE_SUCCESS", text: "CREATE_SUCCESS"},
          {value: "PAYMENT_SUCCESS", text: "PAYMENT_SUCCESS"},
          {value: "WAIT_TO_REFUND", text: "WAIT_TO_REFUND"},
        ],
      },
    };
  },
  methods: {},
  watch: {
    "inputFormPaygateCard.wallet_type"() {
      this.$emit("updateWalletType", this.inputFormPaygateCard.wallet_type);
    },
  },
};
</script>
<style lang="scss" scoped>
button.btn.btn-danger {
  margin-left: 5px;
}
</style>
